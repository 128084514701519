import { gql } from "@apollo/client";

import { conversationEventDefinition } from "@/definitions/conversation/event/conversationEventDefinitions";
import {
  allCallsVoiceReportTableMetricsDefinition,
  uniqueContactsVoiceReportTableMetricsDefinition,
  voiceCallReportOverviewMetricsDefinition,
  voiceReportMetricsVarDefinition,
} from "@/definitions/reporting/voiceReportingDefinitions";

export const GET_AGENTS = gql`
  query GetAgents($nameSearch: String) {
    agents(nameSearch: $nameSearch) {
      totalCount
      results {
        id
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query GetGroups($nameSearch: String) {
    groups(nameSearch: $nameSearch, orderBy: "name") {
      id
      name
    }
  }
`;

export const GET_AGENT_IN_VOICE_REPORT = gql`
  query GetAgentInVoiceReport($id: ID) {
    voiceReporting {
      agents(id: $id) {
        id
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_GROUP_IN_VOICE_REPORT = gql`
  query GetGroupInVoiceReport($id: ID) {
    voiceReporting {
      groups(id: $id) {
        id
        name
      }
    }
  }
`;

export const GET_GROUP_AGENT_IN_VOICE_REPORT = gql`
  query GetGroupAgentInVoiceReport($groupId: ID, $agentId: ID) {
    voiceReporting {
      groups(id: $groupId) {
        id
        name

        agents(id: $agentId) {
          id
          user {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const GET_VOICE_PROVIDER_ACCOUNTS_IN_VOICE_REPORT = gql`
  query GetVoiceProviderAccountsInVoiceReport($id: ID) {
    voiceReporting {
      voiceProviderAccounts(id: $id) {
        id
        name
        accountId
      }
    }
  }
`;

export const GET_VOICE_CALL_DIRECTION = gql`
  query GetVoiceCallDirection {
    __type(name: "VoiceConversationCallDirectionEnum") {
      enumValues {
        name
        description
      }
    }
  }
`;

export const GET_VOICE_CALL_STATUS = gql`
  query GetVoiceCallStatus {
    __type(name: "VoiceConversationConversationStatusEnum") {
      enumValues {
        name
        description
      }
    }
  }
`;

export const GET_CONVERSATION_EVENTS = gql`
  query GetConversationEvents(
    $createdGte: DateTime
    $createdLt: DateTime
    $orderBy: String
    $conversationId: ID
    $limit: Int
    $offset: Int
    $eventTypeEx: [EventEventTypeEnum]
  ) {
    conversations (id: $conversationId) {
      totalCount
      results {
        id
        events(
          createdGte: $createdGte
          createdLt: $createdLt
          orderBy: $orderBy
          limit: $limit
          offset: $offset
          eventTypeEx: $eventTypeEx
        ) {
          offset
          totalCount
          results ${conversationEventDefinition}
        }
      }
    }
  }
`;

/* Below are queries for <ReportingOverviewTabs> component */

export const GET_TEAM_VOICE_CALL_REPORT_OVERVIEW_DATA = gql`
  query GetTeamVoiceCallReportOverviewData(${voiceReportMetricsVarDefinition}) {
    voiceReporting {
      team {
        ${voiceCallReportOverviewMetricsDefinition}
      }
    }
  }
`;

export const GET_GROUP_VOICE_CALL_REPORT_OVERVIEW_DATA = gql`
  query GetGroupVoiceCallReportOverviewData(
    ${voiceReportMetricsVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${voiceCallReportOverviewMetricsDefinition}
      }
    }
  }
`;

export const GET_AGENT_VOICE_CALL_REPORT_OVERVIEW_DATA = gql`
  query GetAgentVoiceCallReportOverviewData(
    ${voiceReportMetricsVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${voiceCallReportOverviewMetricsDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_VOICE_CALL_REPORT_OVERVIEW_DATA = gql`
  query GetGroupAgentVoiceCallReportOverviewData(
    ${voiceReportMetricsVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${voiceCallReportOverviewMetricsDefinition}
        }
      }
    }
  }
`;

export const GET_DID_VOICE_CALL_REPORT_OVERVIEW_DATA = gql`
  query GetDIDVoiceCallReportOverviewData(
    ${voiceReportMetricsVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${voiceCallReportOverviewMetricsDefinition}
      }
    }
  }
`;

/* Below are queries for <VoiceReportTable> component */

export const GET_ALL_CALLS_GROUPS_VOICE_REPORT_TABLE_DATA = gql`
  query GetAllCallsGroupsVoiceReportTableData(
    ${voiceReportMetricsVarDefinition}
    $id: ID
    $limit: Int
    $offset: Int
  ) {
    voiceReporting {
      groups(
        id: $id
        limit: $limit
        offset: $offset
        ) {
          id
          name
          ${allCallsVoiceReportTableMetricsDefinition}
      }
    }
  }
`;
export const GET_UNIQUE_CONTACTS_GROUPS_VOICE_REPORT_TABLE_DATA = gql`
  query GetUniqueContactsGroupsVoiceReportTableData(
    ${voiceReportMetricsVarDefinition}
    $id: ID
    $limit: Int
    $offset: Int
  ) {
    voiceReporting {
      groups(
        id: $id
        limit: $limit
        offset: $offset
        ) {
          id
          name
          ${uniqueContactsVoiceReportTableMetricsDefinition}
      }
    }
  }
`;

export const GET_ALL_CALLS_AGENTS_VOICE_REPORT_TABLE_DATA = gql`
  query GetAllCallsAgentsVoiceReportTableData(
    ${voiceReportMetricsVarDefinition}
    $limit: Int
    $offset: Int
  ) {
    voiceReporting {
      agents(
        limit: $limit
        offset: $offset
      ) {
        id
        user {
          firstName
          lastName
          email
        }
        ${allCallsVoiceReportTableMetricsDefinition}
      }
    }
  }
`;
export const GET_UNIQUE_CONTACTS_AGENTS_VOICE_REPORT_TABLE_DATA = gql`
  query GetUniqueContactsAgentsVoiceReportTableData(
    ${voiceReportMetricsVarDefinition}
    $limit: Int
    $offset: Int
  ) {
    voiceReporting {
      agents(
        limit: $limit
        offset: $offset
      ) {
        id
        user {
          firstName
          lastName
          email
        }
        ${uniqueContactsVoiceReportTableMetricsDefinition}
      }
    }
  }
`;

export const GET_ALL_CALLS_GROUP_AGENT_VOICE_REPORT_TABLE_DATA = gql`
  query GetAllCallsGroupAgentVoiceReportTableData(
    ${voiceReportMetricsVarDefinition}
    $id: ID
    $limit: Int
    $offset: Int
  ) {
    voiceReporting {
      groups(
        id: $id
        limit: $limit
        offset: $offset
      ){
        agents {
          id
          user {
            firstName
            lastName
            email
          }
          ${allCallsVoiceReportTableMetricsDefinition}
        }
      }
    }
  }
`;
export const GET_UNIQUE_CONTACTS_GROUP_AGENT_VOICE_REPORT_TABLE_DATA = gql`
  query GetUniqueContactsGroupAgentVoiceReportTableData(
    ${voiceReportMetricsVarDefinition}
    $id: ID
    $limit: Int
    $offset: Int
  ) {
    voiceReporting {
      groups(
        id: $id
        limit: $limit
        offset: $offset
      ){
        agents {
          id
          user {
            firstName
            lastName
            email
          }
          ${uniqueContactsVoiceReportTableMetricsDefinition}
        }
      }
    }
  }
`;

export const GET_ALL_CALLS_DID_VOICE_REPORT_TABLE_DATA = gql`
  query GetAllCallsDIDVoiceReportTableData(
    ${voiceReportMetricsVarDefinition}
    $limit: Int
    $offset: Int
  ) {
    voiceReporting {
      voiceProviderAccounts(
        limit: $limit
        offset: $offset
      ) {
        id
        name
        accountId
        ${allCallsVoiceReportTableMetricsDefinition}
      }
    }
  }
`;

export const GET_UNIQUE_CONTACTS_DID_VOICE_REPORT_TABLE_DATA = gql`
  query GetUniqueContactsDIDVoiceReportTableData(
    ${voiceReportMetricsVarDefinition}
    $limit: Int
    $offset: Int
  ) {
    voiceReporting {
      voiceProviderAccounts(
        limit: $limit
        offset: $offset
      ) {
        id
        name
        accountId
        ${uniqueContactsVoiceReportTableMetricsDefinition}
      }
    }
  }
`;
