import * as userActions from "@/actions/userActions";
import moment from "moment";

import { EDIT_AI_TIMEOUT_SECONDS } from "@/components/RichTextEditor/utils";

const defaultState = Object.freeze({
  accessToken: null,
  isShortcutModalOpen: false,
  chatMessageEditorDrafts: {},
  editAiResponses: {},
});

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case userActions.GET_ACCESS_TOKEN_SUCCESS: {
      return Object.freeze({
        ...state,
        accessToken: action.accessToken,
      });
    }

    case userActions.SET_SHORTCUT_MODAL_STATE: {
      return Object.freeze({
        ...state,
        isShortcutModalOpen: action.isShortcutModalOpen,
      });
    }

    case userActions.SET_CHAT_MESSAGE_EDITOR_DRAFT: {
      return Object.freeze({
        ...state,
        chatMessageEditorDrafts: {
          ...state.chatMessageEditorDrafts,
          [action.key]: action.value,
        },
      });
    }

    case userActions.SET_EDIT_AI_RESPONSE: {
      const newEditAiResponses = {
        ...state.editAiResponses,
        [action.clientReference]: action.editLog,
      };

      /** If action has no data for the editLog try perform data clean up to reduce memory usage */
      if (!action.editLog) {
        Object.keys(newEditAiResponses).forEach((key) => {
          const editLog = newEditAiResponses[key];

          /** Delete any keys that don't have an editLog */
          if (!editLog) {
            delete newEditAiResponses[key];
          } else {
            /** Delete any editLog object that has lived longer than the editAi timeout */
            const isEditLogOld = moment(editLog.clientReceivedAt).isBefore(
              moment().subtract(EDIT_AI_TIMEOUT_SECONDS, "seconds"),
            );

            if (isEditLogOld) delete newEditAiResponses[key];
          }
        });
      }

      return Object.freeze({
        ...state,
        editAiResponses: newEditAiResponses,
      });
    }

    case userActions.LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
