import { gql } from "@apollo/client";

export const GET_TENANT_ACCOUNT_CONFIGURATION = gql`
  query GetTenantAccountConfiguration {
    tenantAccountConfiguration {
      editAiEnabled
    }
  }
`;

export const GET_EDIT_PROMPTS = gql`
  query GetEditPrompts {
    editPrompts {
      ... on TenantEditPromptObject {
        id
        displayText
        clickable
        parent {
          id
        }
      }

      ... on EditPromptObject {
        id
        displayText
        clickable
        parent {
          id
        }
      }
    }
  }
`;
