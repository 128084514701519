export const dataObjectTypenameEnum = Object.freeze({
  agentIpCallObject: "AgentIPCallObject",
  relatedEventObject: "RelatedEventObject",
  relatedEventObjectListObject: "RelatedEventObjectListObject",
  conversationObject: "ConversationObject",
  conversationObjectListObject: "ConversationObjectListObject",
  conversationObjectPaginatedListObject:
    "ConversationObjectPaginatedListObject",
  conversationMessageObject: "ConversationMessageObject",
  conversationNoteObject: "ConversationNoteObject",
  conversationDispositionSettingObject: "ConversationDispositionSettingObject",
  conversationDispositionFieldObject: "ConversationDispositionFieldObject",
  flowObject: "FlowObject",
  mediaObject: "MediaObject",
  agentObject: "AgentObject",
  groupObject: "GroupObject",
  botUserObject: "BotUserObject",
  agentObjectListObject: "AgentObjectListObject",
  messagingProviderAccountObject: "MessagingProviderAccountObject",
  messagingProviderContactObject: "MessagingProviderContactObject",
  messagingProviderContactObjectListObject:
    "MessagingProviderContactObjectListObject",
  userObject: "UserObject",
  agentOnlineDataObject: "AgentOnlineDataObject",
  tagObject: "TagObject",
  tagObjectListObject: "TagObjectListObject",
  contactObject: "ContactObject",
  contactTagObject: "ContactTagObject",
  conversationTagObject: "ConversationTagObject",
  contentTypeAllowedTagObject: "ContentTypeAllowedTagObject",
  configurationSchemaObject: "ConfigurationSchemaObject",
  crmCsvImportObjectListObject: "CrmCsvImportObjectListObject",
  instanceObject: "InstanceObject",
  instanceListObject: "InstanceListObject",
  instanceObjectListObject: "InstanceObjectListObject",
  videoSessionObject: "VideoSessionObject",
  messagingProviderObject: "MessagingProviderObject",
  voiceConversationObject: "VoiceConversationObject",
  voiceIVRObject: "VoiceIVRObject",
  voiceProviderAccountObject: "VoiceProviderAccountObject",
  voiceProviderContactObject: "VoiceProviderContactObject",
  holidayScheduleCollectionObject: "HolidayScheduleCollectionObject",
  voiceCallContactCandidateObject: "VoiceCallContactCandidateObject",
  notificationObject: "NotificationObject",
  notificationObjectListObject: "NotificationObjectListObject",
  messageBlastScheduleObject: "MessageBlastScheduleObject",
  whatsappHSMTemplateObjectListObject: "WhatsappHSMTemplateObjectListObject",
  relatedEventObjectPaginatedListObject:
    "RelatedEventObjectPaginatedListObject",
  paymentSessionObjectListObject: "PaymentSessionObjectListObject",
  externalCommunicationConfigurationObjectListObject:
    "ExternalCommunicationConfigurationObjectListObject",
  whatsappHSMTranslatedTemplateObject: "WhatsappHSMTranslatedTemplateObject",
  whatsappHSMConversationMessageObject: "WhatsappHSMConversationMessageObject",
  transcriptionSegmentObject: "TranscriptionSegmentObject",
  editPromptObject: "EditPromptObject",
  tenantEditPromptObject: "TenantEditPromptObject",
});
