import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

/* This will be the most verbose definition for the "me" query we have */
const GET_ME = gql`
  query GetMe {
    me {
      id
      isHidden

      user {
        email
        firstName
        lastName
      }

      groups {
        id
        name
      }

      roles {
        id
        permissionCategories {
          id
          roleId
          permissions {
            id
            code
            roleId
            name
            code
            isEnabled
          }
        }
      }
    }
  }
`;

const useMeQuery = ({ objectShape, queryOptions = {} } = {}) => {
  const QUERY = useMemo(() => {
    if (objectShape) {
      return gql`
        query GetMe {
          me ${objectShape}
        }
      `;
    }

    return GET_ME;
  }, [objectShape]);

  return useQuery(QUERY, { ...queryOptions });
};

export default useMeQuery;
