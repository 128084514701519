import Autolinker from "autolinker";
import parse, { domToReact } from "html-react-parser";
import { invert, startCase } from "lodash";
import sanitizeHtml from "sanitize-html";

import MentionRenderer from "@/components/ChatMessage/MentionRenderer";
import { entityTypeEnum } from "@/enums/editor";
import { chatMessageTypeEnum } from "@/enums/messageEnum";
import * as commonUtils from "@/utils/commonUtils";

const messageContentTypeText = invert(chatMessageTypeEnum);

export const hasCaption = (text) => {
  /*
    Captionless incoming media seem to have "<p></p>" instead of "",
  */
  const noCaptionList = ["<p></p>", "", undefined, null];

  return !noCaptionList.includes(text);
};

export const getAutolinkedText = (text) =>
  Autolinker.link(text, { stripPrefix: false });

export const sanitizeString = (string) => {
  const allowedTags = [
    "b",
    "i",
    "p",
    "span",
    "br",
    "strong",
    "em",
    "a",
    "s",
    "code",
    "data",
  ];

  return sanitizeHtml(string, {
    allowedTags,
    disallowedTagsMode: "escape",
    parser: { lowerCaseTags: false },
    allowedAttributes: {
      data: ["data-object", "value", "name"],
      span: ["id", "class"],
    },
  });
};

const prepareHtml = ({ htmlString, mentions, MentionRendererProps }) => {
  const linkedText = getAutolinkedText(htmlString);

  const options = {
    replace: ({ parent, children, name, prev, attribs }) => {
      /* Replace parent <p> tags with <span> */
      if (!parent && name === "p") {
        if (prev !== null) {
          /* Preserves paragraphs */
          /* TODO: Remove if backend switches <p> to <span> */
          return (
            <>
              <br />
              <span>{domToReact(children, options)}</span>
            </>
          );
        }

        return <span>{domToReact(children, options)}</span>;
      }

      /* Replace data tag with mention data to custom mention component */
      if (
        name === "data" &&
        attribs?.["data-object"] === entityTypeEnum.mention
      ) {
        const mention = mentions.find(
          (mention) => mention.id === attribs.value,
        );

        /* If we can't find mention data, just return as normal text */
        if (!mention) return <span>{domToReact(children, options)}</span>;

        return (
          <MentionRenderer mention={mention} {...MentionRendererProps}>
            {domToReact(children, options)}
          </MentionRenderer>
        );
      }
    },
  };

  return parse(linkedText, options);
};

export const parseHtmlMessageString = ({
  htmlString = "",
  showLinkPreview = false,
  mentions = [],
  messageContentType = "",
  MentionRendererProps = {},
}) => {
  const isEmptyHtmlString = !hasCaption(htmlString);

  if (isEmptyHtmlString) {
    return {
      parsedHtml: (
        <span>
          {startCase(messageContentTypeText[messageContentType] || "")}
        </span>
      ),
    };
  }

  const sanitizedString = sanitizeString(htmlString);
  const parsedHtml = prepareHtml({
    htmlString: sanitizedString,
    mentions,
    MentionRendererProps,
  });

  if (!showLinkPreview) return { parsedHtml };

  const urls = commonUtils.getUrls(htmlString);
  const url = urls.length > 0 ? urls[0] : null;

  return { parsedHtml, url };
};
