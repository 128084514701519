import { gql, useQuery, useSubscription } from "@apollo/client";
import { useDispatch } from "react-redux";

import { setEditAiResponse } from "@/src/actions/userActions";
import moment from "moment";
import { GET_TENANT_ACCOUNT_CONFIGURATION } from "../queries";

const EDIT_LOG_CHANGE_SUBSCRIPTION = gql`
  subscription onEditLogChange {
    onEditLogChange {
      editLog {
        id
        clientReference
        responseText
        status
        errorDetails
      }
    }
  }
`;

const RichTextEditorSubscriptions = () => {
  const dispatch = useDispatch();

  const { data: tenantAccountConfigurationData } = useQuery(
    GET_TENANT_ACCOUNT_CONFIGURATION,
  );

  const { editAiEnabled: isEditAiEnabled } =
    tenantAccountConfigurationData?.tenantAccountConfiguration || {};

  useSubscription(EDIT_LOG_CHANGE_SUBSCRIPTION, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    skip: !isEditAiEnabled,
    onData: ({ data }) => {
      if (!data.data?.onEditLogChange.editLog) return;

      const { editLog } = data.data.onEditLogChange;

      dispatch(
        setEditAiResponse({
          clientReference: editLog.clientReference,
          editLog: { ...editLog, clientReceivedAt: moment().toISOString() },
        }),
      );
    },
  });

  return null;
};

export default RichTextEditorSubscriptions;
