import allowedInboxViewEnum, {
  allowedInboxViewSectionEnum,
} from "@/enums/allowedInboxViewEnum";
import {
  conversationStatusEnum,
  conversationStatusInEnum,
} from "@/enums/conversation";
import inboxPageData from "@/pagesData/inbox";

export const getInboxViewInput = ({ inboxViewSection, inboxViewId }) => {
  if (inboxViewId === allowedInboxViewEnum.priority) {
    return { isPriority: true };
  }

  switch (inboxViewSection) {
    case allowedInboxViewSectionEnum.special: {
      return { allowedInboxViewInput: { special: inboxViewId } };
    }

    case allowedInboxViewSectionEnum.agent: {
      return { allowedInboxViewInput: { agent: inboxViewId } };
    }

    case allowedInboxViewSectionEnum.group: {
      return { allowedInboxViewInput: { group: inboxViewId } };
    }

    case allowedInboxViewSectionEnum.conversationTags: {
      return { allowedInboxViewInput: { conversationTags: [inboxViewId] } };
    }

    case allowedInboxViewSectionEnum.contactTags: {
      return { allowedInboxViewInput: { contactTags: [inboxViewId] } };
    }

    default: {
      return {};
    }
  }
};

export const validateInboxPageUrlForConversation = ({
  router,
  conversationStatus,
  conversationAllowedInboxView,
}) => {
  const isInboxPage = router.pathname === inboxPageData.urlObject.pathname;
  if (!isInboxPage) return true;

  const { inboxViewSection, inboxViewId, status } = router.query;

  const isInboxViewValid = (() => {
    switch (inboxViewSection) {
      case allowedInboxViewSectionEnum.special: {
        return conversationAllowedInboxView.special.includes(inboxViewId);
      }

      case allowedInboxViewSectionEnum.agent: {
        return conversationAllowedInboxView.agent === inboxViewId;
      }

      case allowedInboxViewSectionEnum.group: {
        return conversationAllowedInboxView.group === inboxViewId;
      }

      case allowedInboxViewSectionEnum.conversationTags: {
        return conversationAllowedInboxView.conversationTags.includes(
          inboxViewId,
        );
      }

      case allowedInboxViewSectionEnum.contactTags: {
        return conversationAllowedInboxView.contactTags.includes(inboxViewId);
      }

      default: {
        return false;
      }
    }
  })();

  const isStatusValid = (() => {
    switch (status) {
      case conversationStatusEnum.active: {
        return conversationStatusInEnum.active.includes(conversationStatus);
      }

      case conversationStatusEnum.resolved: {
        return conversationStatusInEnum.resolved.includes(conversationStatus);
      }

      default: {
        return false;
      }
    }
  })();

  return isInboxViewValid && isStatusValid;
};
