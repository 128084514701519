export const voiceCallInvitationEventTypeEnum = Object.freeze({
  VOICE_CALL_INVITATION_CANCELLED: "VOICE_CALL_INVITATION_CANCELLED",
  VOICE_CALL_INVITATION_DECLINED: "VOICE_CALL_INVITATION_DECLINED",
  VOICE_CALL_INVITATION_TIMEOUT: "VOICE_CALL_INVITATION_TIMEOUT",
  VOICE_CALL_INVITATION_BUSY: "VOICE_CALL_INVITATION_BUSY",
  VOICE_CALL_INVITATION_FAILED: "VOICE_CALL_INVITATION_FAILED",
});

export const voiceCallEventTypeEnum = Object.freeze({
  VOICE_AGENT_IP_CALL: "VOICE_AGENT_IP_CALL",
  VOICE_AGENT_IP_CALL_MISSED: "VOICE_AGENT_IP_CALL_MISSED",
  VOICE_CALL_STARTED: "VOICE_CALL_STARTED",
  VOICE_CALL_JOINED: "VOICE_CALL_JOINED",
  VOICE_CALL_ENDED: "VOICE_CALL_ENDED",
  VOICE_CALL_LEFT: "VOICE_CALL_LEFT",
  VOICE_CALL_ABANDONED: "VOICE_CALL_ABANDONED",
  VOICE_CALL_MISSED: "VOICE_CALL_MISSED",
  VOICE_CALL_CANCELLED: "VOICE_CALL_CANCELLED",
  VOICE_CALL_DECLINED: "VOICE_CALL_DECLINED",
  VOICE_CALL_TIMEOUT: "VOICE_CALL_TIMEOUT",
  VOICE_CALL_BUSY: "VOICE_CALL_BUSY",
  VOICE_CALL_FAILED: "VOICE_CALL_FAILED",
  VOICE_CALL_MOVED_TO_CHAT: "VOICE_CALL_MOVED_TO_CHAT",
  VOICE_CALL_TRANSFER_MISSED: "VOICE_CALL_TRANSFER_MISSED",
  VOICE_CALL_TRANSFER_DECLINED: "VOICE_CALL_TRANSFER_DECLINED",
  VOICE_CALL_TRANSFER_TIMEOUT: "VOICE_CALL_TRANSFER_TIMEOUT",
  VOICE_CALL_INVITED: "VOICE_CALL_INVITED",
  VOICE_CALL_HOLD: "VOICE_CALL_HOLD",
  VOICE_CALL_UNHOLD: "VOICE_CALL_UNHOLD",
  VOICE_CALL_LEG_MUTED: "VOICE_CALL_LEG_MUTED",
  VOICE_CALL_LEG_UNMUTED: "VOICE_CALL_LEG_UNMUTED",
  VOICE_CALL_RECORDED: "VOICE_CALL_RECORDED",
  VOICE_CALL_RECORDING_PLAYBACK_READY: "VOICE_CALL_RECORDING_PLAYBACK_READY",
  VOICE_CALL_VOICEMAIL: "VOICE_CALL_VOICEMAIL",
  VOICE_CALL_INTERACTION: "VOICE_CALL_INTERACTION",
  VOICE_CALL_SWITCH_AWAY: "VOICE_CALL_SWITCH_AWAY",
  VOICE_CALL_SWITCH_TO: "VOICE_CALL_SWITCH_TO",
  VOICE_CALL_ANSWERED: "VOICE_CALL_ANSWERED",
  VOICE_CALL_UNANSWERED: "VOICE_CALL_UNANSWERED",
  VOICE_CALL_COMPLETED: "VOICE_CALL_COMPLETED",
  VOICE_CALL_MONITOR_STARTED: "VOICE_CALL_MONITOR_STARTED",
  VOICE_CALL_MONITOR_ENDED: "VOICE_CALL_MONITOR_ENDED",
  VOICE_CALL_CHANNEL_RECORDING_TRANSCRIBED:
    "VOICE_CALL_CHANNEL_RECORDING_TRANSCRIBED",
  VOICE_CALL_TRANSCRIPTION_COMPLETED: "VOICE_CALL_TRANSCRIPTION_COMPLETED",
  VOICE_CALL_TRANSLATION_COMPLETED: "VOICE_CALL_TRANSLATION_COMPLETED",
  ...voiceCallInvitationEventTypeEnum,
});

/* Used to describe voice call actions based on the event type in getVoiceCallActionDescription */
export const voiceCallEventTypeActionDescriptionLabel = Object.freeze({
  [voiceCallEventTypeEnum.VOICE_CALL_JOINED]: "joined the call",
  [voiceCallEventTypeEnum.VOICE_CALL_ENDED]: "ended the voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_LEFT]: "left the voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_HOLD]: "hold the voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_UNHOLD]: "unhold the voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_CANCELLED]: "cancelled the voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_LEG_MUTED]: "is muted from voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_LEG_UNMUTED]: "is unmuted from voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_ABANDONED]: "abandoned the voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_MISSED]: "cancelled the voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_DECLINED]: "declined the voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_BUSY]: "was busy to answer the voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_TIMEOUT]:
    "Call is not answered within max ring time",
  [voiceCallEventTypeEnum.VOICE_CALL_TRANSFER_MISSED]:
    "left the voice call during transfer",
  [voiceCallEventTypeEnum.VOICE_CALL_TRANSFER_DECLINED]:
    "declined the voice call transfer",
  [voiceCallEventTypeEnum.VOICE_CALL_TRANSFER_TIMEOUT]:
    "Call transfer is not answered within max ring time",
  [voiceCallEventTypeEnum.VOICE_CALL_INVITATION_CANCELLED]:
    "cancelled the voice call invitation",
  [voiceCallEventTypeEnum.VOICE_CALL_INVITATION_DECLINED]:
    "declined the voice call invitation",
  [voiceCallEventTypeEnum.VOICE_CALL_INVITATION_TIMEOUT]:
    "Call invitation is not answered within max ring time",
  [voiceCallEventTypeEnum.VOICE_CALL_INVITATION_BUSY]:
    "was busy to answer voice call invitation",
  [voiceCallEventTypeEnum.VOICE_CALL_INVITATION_FAILED]:
    "failed to invite contact to voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_RECORDED]: "Voice call recording is ready",
  [voiceCallEventTypeEnum.VOICE_CALL_VOICEMAIL]: "left a voicemail",
  [voiceCallEventTypeEnum.VOICE_CALL_MOVED_TO_CHAT]:
    "continue the conversation in chat",
  [voiceCallEventTypeEnum.VOICE_CALL_SWITCH_AWAY]:
    "switched to other voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_SWITCH_TO]: "switched back to voice call",
  [voiceCallEventTypeEnum.VOICE_CALL_MONITOR_STARTED]:
    "started monitoring the call",
  [voiceCallEventTypeEnum.VOICE_CALL_MONITOR_ENDED]:
    "stopped monitoring the call",
});
