export const voiceCallFailureDetailEnum = Object.freeze({
  SUBSCRIBER_UNAVAILABLE: "SUBSCRIBER_UNAVAILABLE",
  CARRIER_DECLINED: "CARRIER_DECLINED",
  CANNOT_ROUTE: "CANNOT_ROUTE",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  NUMBER_OUT_OF_SERVICE: "NUMBER_OUT_OF_SERVICE",
  INVALID_NUMBER: "INVALID_NUMBER",
  CARRIER_TIMEOUT: "CARRIER_TIMEOUT",
  BLOCKED_NUMBER: "BLOCKED_NUMBER",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
});

export const voiceCallFailureDescriptionLabel = Object.freeze({
  [voiceCallFailureDetailEnum.SUBSCRIBER_UNAVAILABLE]:
    "The number is temporarily unavailable. Please try again later.",
  [voiceCallFailureDetailEnum.CARRIER_DECLINED]:
    "The call was rejected by the contact's telco. Please check if the number is correct.",
  [voiceCallFailureDetailEnum.CANNOT_ROUTE]:
    "The number is not supported or is blocked. Please contact your admin.",
  [voiceCallFailureDetailEnum.INTERNAL_ERROR]:
    "An error occurred. Please try again later.",
  [voiceCallFailureDetailEnum.NUMBER_OUT_OF_SERVICE]:
    "The number is out of service. Please check if the number is correct.",
  [voiceCallFailureDetailEnum.INVALID_NUMBER]:
    "The number is invalid. Please check if the number is correct.",
  [voiceCallFailureDetailEnum.CARRIER_TIMEOUT]:
    "The contact's telco did not respond within the timeout period. Please try again later.",
  [voiceCallFailureDetailEnum.BLOCKED_NUMBER]:
    "The number has been blocked by your admin. Please contact your admin.",
  [voiceCallFailureDetailEnum.UNKNOWN_ERROR]:
    "An error occurred. Please try again later.",
});
