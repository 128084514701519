import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import html2canvas from "html2canvas";
import { isEmpty, isNil, omit, toNumber } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import * as privateVideoCallActions from "@/actions/privateVideoCallActions";
import {
  useAuthContext,
  useAuthenticatedUserContext,
} from "@/contextProviders/AuthProvider";
import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";
import { meUserIdDefinition } from "@/definitions/meDefinitions";
import { severityEnum } from "@/enums/styleVariantEnum";
import { videoCallParticipantTypeEnum } from "@/enums/videoCall";
import * as videoCallMutations from "@/mutations/videoCallMutations";
import inboxPageData from "@/pagesData/inbox";
import * as videoCallQueries from "@/queries/videoCallQueries";
import * as conversationSelectors from "@/selectors/conversationSelectors";
import * as privateVideoCallSelectors from "@/selectors/privateVideoCallSelectors";
import * as userSelectors from "@/selectors/userSelectors";
import * as voiceCallSelectors from "@/selectors/voiceCallSelectors";
import mediaApi from "@/services/api/mediaApi";
import useMeQuery from "@/services/queryHooks/useMeQuery";
import { useValueRef } from "@/utils/hookUtils";
import * as videoCallUtils from "@/utils/videoCallUtils";

const PrivateVideoCallContext = createContext({});
const PrivateVideoCallMeetingRoomContext = createContext({});
const PrivateOngoingVideoCallSessionContext = createContext({});
const PrivateVideoCallButtonContext = createContext({});
const PrivateVideoCallDurationContext = createContext({});

const PrivateVideoCallProvider = ({ children }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { isHiddenSupportUser } = useAuthenticatedUserContext();

  const { onSetAppSnackbarProps } = useAppSnackbar();
  const { isUserLoggedIn } = useAuthContext();

  const token = useSelector(userSelectors.accessToken);
  const opentokSession = useSelector(privateVideoCallSelectors.opentokSession);
  const changedStream = useSelector(privateVideoCallSelectors.changedStream);
  const streams = useSelector(privateVideoCallSelectors.streams);
  const connections = useSelector(privateVideoCallSelectors.connections);
  const previousPublisher = useSelector(
    privateVideoCallSelectors.previousPublisher,
  );
  const callTimer = useSelector(privateVideoCallSelectors.callTimer);
  const waitingTimer = useSelector(privateVideoCallSelectors.waitingTimer);

  const isFloatingVideoCall = useSelector(
    privateVideoCallSelectors.isFloatingVideoCall,
  );
  const isScreenshotLoading = useSelector(
    privateVideoCallSelectors.isScreenshotLoading,
  );
  const conversationIdStartingVideoCall = useSelector(
    privateVideoCallSelectors.conversationIdStartingVideoCall,
  );
  const ongoingVideoCallConversationId = useSelector(
    privateVideoCallSelectors.ongoingVideoCallConversationId,
  );

  const hasVideoSession = useSelector(
    privateVideoCallSelectors.hasVideoSession,
  );

  const isVideoCallInUse = useSelector(
    privateVideoCallSelectors.isVideoCallInUse,
  );

  const { initiationStatus } = useSelector(
    conversationSelectors.conversationInitiationState,
  );

  const isVoiceCallInUse = useSelector(voiceCallSelectors.isVoiceCallInUse);

  const { data: meQueryData } = useMeQuery({ objectShape: meUserIdDefinition });
  const { id: userId } = meQueryData?.me || {};

  const currentConversationId = useMemo(() => {
    const isInboxPage = router.pathname === inboxPageData.urlObject.pathname;
    if (!isInboxPage) return null;

    if (!toNumber(router.query.conversationId)) return null;

    return router.query.conversationId;
  }, [router.pathname, router.query.conversationId]);

  const isStartingVideoCall = !!conversationIdStartingVideoCall;

  /* Indicates if the contact has joined the video session */
  const hasContactJoined = useMemo(
    () =>
      connections.some((connection) => {
        const { participant_type } = JSON.parse(connection.data);
        return participant_type === videoCallParticipantTypeEnum.CONTACT;
      }),
    [connections],
  );

  const setPreviousPublisher = useCallback(
    (previousPublisher) => {
      dispatch(
        privateVideoCallActions.setPreviousPublisher({ previousPublisher }),
      );
    },
    [dispatch],
  );

  const setConversationStartingVideoCall = useCallback(
    (conversationId = null) => {
      dispatch(
        privateVideoCallActions.setConversationStartingVideoCall({
          conversationIdStartingVideoCall: conversationId,
        }),
      );
    },
    [dispatch],
  );

  const setIsScreenshotLoading = useCallback(
    (isScreenshotLoading) => {
      dispatch(
        privateVideoCallActions.setIsScreenshotLoading({
          isScreenshotLoading,
        }),
      );
    },
    [dispatch],
  );

  const setSuccessMessageSnackbar = (message) => {
    onSetAppSnackbarProps({
      message,
      SnackbarProps: { autoHideDuration: 1500 },
      AlertProps: { severity: severityEnum.success },
    });
  };

  const setErrorMessageSnackbar = useCallback(
    (message) => {
      onSetAppSnackbarProps({
        message,
        AlertProps: { severity: severityEnum.error },
      });
    },
    [onSetAppSnackbarProps],
  );

  const handleStreamPropertyChanged = ({ stream, changedProperty }) => {
    if (
      !videoCallUtils.allowedStreamPropertyChangedEvents.includes(
        changedProperty,
      )
    ) {
      return;
    }
    const { id, connection, hasVideo, hasAudio } = stream;

    dispatch(
      privateVideoCallActions.setChangedStream({
        changedStream: { id, connection, hasVideo, hasAudio },
      }),
    );
  };

  const handleConnectionCreated = ({ connection }) => {
    const { participant_type } = JSON.parse(connection.data);
    const hasContactJoined =
      participant_type === videoCallParticipantTypeEnum.CONTACT;

    if (hasContactJoined) {
      dispatch(
        privateVideoCallActions.setCallTimer({ callTimer: moment().format() }),
      );
    }

    dispatch(privateVideoCallActions.addConnection({ connection }));
  };

  const handleConnnectionDestroyedValueRefs = useValueRef({
    connections,
  });

  const handleConnectionDestroyed = ({ connection }) => {
    const newConnections =
      handleConnnectionDestroyedValueRefs.current.connections.filter(
        ({ id }) => id !== connection.id,
      );

    const hasAllContactLeft = !newConnections.some((connection) => {
      const { participant_type } = JSON.parse(connection.data);
      return participant_type === videoCallParticipantTypeEnum.CONTACT;
    });

    if (hasAllContactLeft) {
      dispatch(
        privateVideoCallActions.setWaitingTimer({
          waitingTimer: moment().format(),
        }),
      );
    }

    dispatch(privateVideoCallActions.removeConnection({ connection }));
  };

  const handleStreamCreated = ({ stream }) => {
    dispatch(privateVideoCallActions.addStream({ stream }));
  };

  const handleStreamDestroyed = ({ stream }) => {
    dispatch(privateVideoCallActions.removeStream({ stream }));
  };

  const handleSessionDisconnected = useCallback(
    (opentokSession) => {
      /* Remove all event handler associated with the current session */
      opentokSession.off();
      dispatch(privateVideoCallActions.resetVideoCallStates());
    },
    [dispatch],
  );

  const connect = async (credentials) => {
    try {
      const OT = await import("@opentok/client");

      const isWebRtcSupported = Boolean(OT.checkSystemRequirements());

      if (!isWebRtcSupported) {
        throw new Error(
          "Your browser does not support video call. Please use another browser",
        );
      }

      const opentokSession = OT.initSession(
        credentials.accountId,
        credentials.sessionId,
      );

      /* Add listeners for opentok events before connecting to session */
      opentokSession.on("sessionDisconnected", () =>
        handleSessionDisconnected(opentokSession),
      );
      opentokSession.on("connectionCreated", handleConnectionCreated);
      opentokSession.on("connectionDestroyed", handleConnectionDestroyed);
      opentokSession.on("streamCreated", handleStreamCreated);
      opentokSession.on("streamDestroyed", handleStreamDestroyed);
      opentokSession.on("streamPropertyChanged", handleStreamPropertyChanged);

      await new Promise((resolve, reject) => {
        opentokSession.connect(credentials.token, (error) => {
          if (error) {
            opentokSession.off();
            reject(error);
          } else resolve();
        });
      });

      dispatch(privateVideoCallActions.setOpentokSession({ opentokSession }));
      dispatch(
        privateVideoCallActions.setWaitingTimer({
          waitingTimer: moment().format(),
        }),
      );
      dispatch(
        privateVideoCallActions.setOngoingVideoCallConversationId({
          ongoingVideoCallConversationId: conversationIdStartingVideoCall,
        }),
      );
    } catch (error) {
      setErrorMessageSnackbar(`${error.name} - ${error.message}`);
    }

    setConversationStartingVideoCall();
  };

  const [generateVideoSessionToken] = useMutation(
    videoCallMutations.GENERATE_VIDEO_SESSION_TOKEN,
    {
      onError: ({ message }) => {
        setConversationStartingVideoCall();
        setErrorMessageSnackbar(message);
      },
      onCompleted: ({ generateVideoSessionToken }) => {
        const credentials = omit(generateVideoSessionToken, "__typename");
        connect(credentials);
      },
    },
  );

  const handleGenerateVideoSessionToken = ({
    conversationAssignee,
    conversationId,
    videoSessionId,
  }) => {
    const isConversationAssignedToMe = userId === conversationAssignee?.id;

    const isExistingConnection = connections.some(
      (connection) => connection.id === opentokSession?.connection?.id,
    );

    const shouldSkip =
      !videoSessionId ||
      !isConversationAssignedToMe ||
      isExistingConnection ||
      isVoiceCallInUse;

    if (shouldSkip) {
      setConversationStartingVideoCall();
      return;
    }

    setConversationStartingVideoCall(conversationId);

    generateVideoSessionToken({
      variables: { input: { isModerator: true, videoSessionId } },
    });
  };

  const [createVideoScreenshot] = useMutation(
    videoCallMutations.CREATE_VIDEO_SCREENSHOT,
    {
      onCompleted: ({ createVideoSessionScreenshot }) => {
        const { filename } = createVideoSessionScreenshot.instance.media;
        const message = `${filename} saved to cloud`;
        setIsScreenshotLoading(false);
        setSuccessMessageSnackbar(message);
      },
    },
  );

  const { data: { videoProviderAccounts = [] } = {} } = useQuery(
    videoCallQueries.GET_VIDEO_PROVIDER_ACCOUNT,
    { fetchPolicy: "no-cache", skip: !isUserLoggedIn },
  );

  const [
    getVideoSessionData,
    { data: videoSessionData, loading: videoSessionDataLoading },
  ] = useLazyQuery(videoCallQueries.GET_VIDEO_SESSION_DATA, {
    fetchPolicy: "cache-and-network",
    onError: () => {
      setConversationStartingVideoCall();
    },
    onCompleted: ({ conversations: { results = [] } = {} } = {}) => {
      const conversation = results[0] || {};
      const conversationId = conversation.id;
      const videoSessionId = conversation.ongoingVideoSession?.id;
      const conversationAssignee = conversation.assignee;

      handleGenerateVideoSessionToken({
        conversationId,
        conversationAssignee,
        videoSessionId,
      });
    },
  });

  const { ongoingVideoSession } =
    videoSessionData?.conversations?.results?.[0] || {};

  const isConversationAssignedToMe =
    ongoingVideoSession?.conversation.assignee.id === userId;

  const [createVideoSession] = useMutation(
    videoCallMutations.CREATE_VIDEO_SESSION,
  );

  const [endVideoSession, { loading: isEndVideoSessionLoading }] = useMutation(
    videoCallMutations.END_VIDEO_SESSION,
    { onError: ({ message }) => setErrorMessageSnackbar(message) },
  );

  const handleScreenshot = useCallback(
    async (containerRef) => {
      setIsScreenshotLoading(true);

      const canvas = await html2canvas(containerRef.current, {
        backgroundColor: "#0e0e0e",
      });
      const dataUrl = canvas.toDataURL("image/png");
      const formattedFile = await videoCallUtils.dataUrlToPngFile(
        dataUrl,
        "video-screenshot.png",
      );

      mediaApi.uploadMedia({
        file: formattedFile,
        token,
        onSuccess: ({ uuid }) =>
          createVideoScreenshot({
            variables: {
              input: {
                media: uuid,
                videoSession: ongoingVideoSession?.id,
              },
            },
          }),
        onError: (error) =>
          setErrorMessageSnackbar(`${error.name} - ${error.message}`),
      });
    },
    [
      token,
      ongoingVideoSession?.id,
      createVideoScreenshot,
      setErrorMessageSnackbar,
      setIsScreenshotLoading,
    ],
  );

  const handleScreenShare = useCallback(async (screenPublisher) => {
    if (screenPublisher.stream) {
      screenPublisher.unpublish();
    } else {
      const OT = await import("@opentok/client");
      OT.checkScreenSharingCapability(
        ({ supported, extensionRegistered, extensionInstalled }) => {
          if (
            !supported ||
            extensionRegistered === false ||
            extensionInstalled === false
          ) {
            alert(
              "Screen sharing is not supported in your browser.\nPlease update your browser to the latest version.",
            );
          } else {
            screenPublisher.publish({
              PublisherProps: { videoSource: "screen" },
            });
          }
        },
      );
    }
  }, []);

  /* Let us know when we are fetching video session data and trying an automatic connection */
  useEffect(() => {
    dispatch(
      privateVideoCallActions.setIsVideoSessionDataLoading({
        isVideoSessionDataLoading: videoSessionDataLoading,
      }),
    );
  }, [videoSessionDataLoading, dispatch]);

  const valueRefs = useValueRef({
    isVoiceCallInUse,
    isVideoCallInUse,
    getVideoSessionData,
  });

  /* Automatically attempt to connect agent to video session if they aren't in one already */
  useEffect(() => {
    const { isVoiceCallInUse, isVideoCallInUse, getVideoSessionData } =
      valueRefs.current;

    if (!isUserLoggedIn) return;
    if (!currentConversationId) return;
    if (isHiddenSupportUser) return;
    if (isVoiceCallInUse) return;
    if (isVideoCallInUse) return;

    /* Edge case for conversation initiation, don't try fetch data until we reset the initiation state */
    if (!isNil(initiationStatus)) return;

    getVideoSessionData({
      variables: { id: currentConversationId },
    });
  }, [
    valueRefs,
    isUserLoggedIn,
    currentConversationId,
    isHiddenSupportUser,
    initiationStatus,
  ]);

  /* Let us know if we are connected to a video session */
  useEffect(() => {
    dispatch(
      privateVideoCallActions.setHasVideoSession({
        hasVideoSession: !!opentokSession,
      }),
    );
  }, [opentokSession, dispatch]);

  useEffect(() => {
    /* When contact reload the public video call page, previous publisher need to be unpublished */
    if (!hasContactJoined && previousPublisher?.publisher) {
      /* Retain floating publisher when contact leave the call to avoid re-publishing */
      if (isFloatingVideoCall) return;
      previousPublisher.unpublish();
    }
  }, [hasContactJoined, previousPublisher, isFloatingVideoCall]);

  /* When conversation is transferred to others, leave the call */
  useEffect(() => {
    if (!opentokSession) return;
    if (isConversationAssignedToMe) return;

    opentokSession.disconnect();
  }, [isConversationAssignedToMe, opentokSession]);

  const startVideoCall = useCallback(
    async ({ conversationId, successCallback }) => {
      if (!conversationId) return;
      if (isStartingVideoCall) {
        setErrorMessageSnackbar("Starting video call in progress");
        return;
      }
      if (hasVideoSession) {
        setErrorMessageSnackbar(
          "Can not have more than one video call at a time",
        );
        return;
      }

      if (isVoiceCallInUse) {
        setErrorMessageSnackbar(
          "Can not start video call while voice call is active",
        );
        return;
      }

      setConversationStartingVideoCall(conversationId);

      try {
        /* Step 1: Create the video session for the conversation */
        const { data } = await createVideoSession({
          variables: { input: { conversation: conversationId } },
        });

        /* Step 2: Generate a session token and connect the agent to to the video call session */
        const videoSessionId = data.createVideoSession.instance.id;
        await generateVideoSessionToken({
          variables: { input: { isModerator: true, videoSessionId } },
        });

        /* Step 3: Run success callback  */
        successCallback && successCallback();
      } catch (error) {
        setConversationStartingVideoCall();
        setErrorMessageSnackbar(error.message);
      }
    },
    [
      isStartingVideoCall,
      isVoiceCallInUse,
      hasVideoSession,
      createVideoSession,
      generateVideoSessionToken,
      setConversationStartingVideoCall,
      setErrorMessageSnackbar,
    ],
  );

  const endVideoCall = useCallback(
    (videoSessionId) => {
      if (!videoSessionId) return;
      if (isEndVideoSessionLoading) {
        setErrorMessageSnackbar("Ending video call in progress");
        return;
      }

      endVideoSession({ variables: { input: { videoSessionId } } });
    },
    [isEndVideoSessionLoading, endVideoSession, setErrorMessageSnackbar],
  );

  const isVideoCallAvailable =
    !isHiddenSupportUser && !isEmpty(videoProviderAccounts);

  const privateVideoCallContextValue = useMemo(() => {
    return {
      opentokSession,
      connections,
      streams,
      changedStream,
      previousPublisher,
      ongoingVideoCallConversationId,
      onSetPreviousPublisher: setPreviousPublisher,
      onResetVideoCallStates: handleSessionDisconnected,
    };
  }, [
    opentokSession,
    connections,
    streams,
    changedStream,
    previousPublisher,
    ongoingVideoCallConversationId,
    setPreviousPublisher,
    handleSessionDisconnected,
  ]);

  const privateOngoingVideoCallSessionContextValue = useMemo(() => {
    return { ongoingVideoSession };
  }, [ongoingVideoSession]);

  const privateVideoCallMeetingRoomContextValue = useMemo(() => {
    return {
      isScreenshotLoading,
      onScreenshot: handleScreenshot,
      onShareScreen: handleScreenShare,
      onToggleCamera: videoCallUtils.toggleCamera,
      onToggleMicrophone: videoCallUtils.toggleMicrophone,
    };
  }, [isScreenshotLoading, handleScreenshot, handleScreenShare]);

  const privateVideoCallButtonContextValue = useMemo(() => {
    return {
      hasContactJoined,
      isVideoCallAvailable,
      isEndingVideoSession: isEndVideoSessionLoading,
      isStartingVideoCall,
      isVoiceCallInUse,
      onStartVideoCall: startVideoCall,
      onEndVideoCall: endVideoCall,
    };
  }, [
    hasContactJoined,
    isVideoCallAvailable,
    isEndVideoSessionLoading,
    isStartingVideoCall,
    isVoiceCallInUse,
    startVideoCall,
    endVideoCall,
  ]);

  const privateVideoCallDurationContextValue = useMemo(() => {
    return { callTimer, waitingTimer };
  }, [callTimer, waitingTimer]);

  return (
    <PrivateVideoCallContext.Provider value={privateVideoCallContextValue}>
      <PrivateOngoingVideoCallSessionContext.Provider
        value={privateOngoingVideoCallSessionContextValue}
      >
        <PrivateVideoCallMeetingRoomContext.Provider
          value={privateVideoCallMeetingRoomContextValue}
        >
          <PrivateVideoCallButtonContext.Provider
            value={privateVideoCallButtonContextValue}
          >
            <PrivateVideoCallDurationContext.Provider
              value={privateVideoCallDurationContextValue}
            >
              {children}
            </PrivateVideoCallDurationContext.Provider>
          </PrivateVideoCallButtonContext.Provider>
        </PrivateVideoCallMeetingRoomContext.Provider>
      </PrivateOngoingVideoCallSessionContext.Provider>
    </PrivateVideoCallContext.Provider>
  );
};

export const usePrivateVideoCallContext = () =>
  useContext(PrivateVideoCallContext);

export const usePrivateOngoingVideoCallSessionContext = () =>
  useContext(PrivateOngoingVideoCallSessionContext);

export const usePrivateVideoCallMeetingRoomContext = () =>
  useContext(PrivateVideoCallMeetingRoomContext);

export const usePrivateVideoCallButtonContext = () =>
  useContext(PrivateVideoCallButtonContext);

export const usePrivateVideoCallDurationContext = () =>
  useContext(PrivateVideoCallDurationContext);

export default PrivateVideoCallProvider;
