import { parsePhoneNumber } from "libphonenumber-js/max";
import { filter, flow, isEmpty, map, sortBy } from "lodash";

import { dataObjectTypenameEnum } from "@/enums/typename";
import {
  voiceCallEventTypeActionDescriptionLabel,
  voiceCallEventTypeEnum,
  voiceCallParticipantTypeEnum,
} from "@/enums/voiceCall";
import { voiceCallFailureDescriptionLabel } from "@/enums/voiceCall/voiceCallFailureDetailEnum";
import { getAgentFullName } from "./commonUtils";
import { getFormattedPhoneNumber, getNameInitials } from "./contactUtils";
import { getConversationEventUnionObjectLabel } from "./inboxPageUtils";

export const defaultConversationTransferSelectorOption = {
  label: "Keep Assigned to me",
  value: null,
};

export const getVoiceCallParticipantNames = ({
  currentParticipants,
  shouldMaskPhoneNumber,
}) => {
  if (!currentParticipants) return [];

  return currentParticipants.map((voiceCallParticipant) => {
    const { participantType, agent, voiceProviderContact } =
      voiceCallParticipant || {};

    switch (participantType) {
      case voiceCallParticipantTypeEnum.agent: {
        return getAgentFullName(agent);
      }

      case voiceCallParticipantTypeEnum.external: {
        if (!voiceProviderContact?.contact) return;

        return getFormattedPhoneNumber({
          displayName: voiceProviderContact.contact.displayName,
          shouldMaskPhoneNumber,
        });
      }

      default: {
        return "";
      }
    }
  });
};

export const getParticipantFromVoiceCall = ({
  voiceCallParticipants = [],
  participantType,
  participantId,
}) => {
  if (isEmpty(voiceCallParticipants)) return;

  switch (participantType) {
    case voiceCallParticipantTypeEnum.agent: {
      return voiceCallParticipants.find(
        (item) => item.agent?.id === participantId,
      );
    }

    case voiceCallParticipantTypeEnum.external: {
      return voiceCallParticipants.find(
        (item) => item.voiceProviderContact?.id === participantId,
      );
    }

    default: {
      return;
    }
  }
};

export const getOtherActiveAgentsFromCall = ({
  currentParticipants,
  userId,
  returnAsAgentObjects = true,
}) => {
  if (!currentParticipants) return [];

  return flow(
    (currentParticipants) => {
      return filter(
        currentParticipants,
        ({ agent, isActive, participantType }) =>
          participantType === voiceCallParticipantTypeEnum.agent &&
          agent.id !== userId &&
          isActive,
      );
    },

    (filteredParticipants) => {
      return returnAsAgentObjects
        ? filteredParticipants.map(({ agent }) => agent)
        : filteredParticipants;
    },
  )(currentParticipants);
};

export const getIsVoiceCallStartedByMe = ({ voiceConversation, userId }) => {
  const { initiatorVoiceLeg } = voiceConversation || {};
  const { agent } = initiatorVoiceLeg || {};
  return agent?.id === userId;
};

export const parseVoiceCallParticipants = (currentParticipants) => {
  if (!currentParticipants) return { agents: [], contacts: [] };

  const parsedParticipants = currentParticipants.reduce(
    (acc, val) => {
      const {
        participantType,
        isMuted,
        voiceProviderContact,
        agent,
        isActive,
        isCallMonitor,
      } = val;

      switch (participantType) {
        case voiceCallParticipantTypeEnum.agent: {
          const agentName = getAgentFullName(agent);

          return {
            ...acc,
            agents: [
              ...acc.agents,
              {
                id: `agent-${agent.id}`,
                initials: getNameInitials(agentName),
                hasAudio: !isMuted,
                tooltipTitle: `${agentName}${
                  isCallMonitor ? " (Call Monitor)" : ""
                }`,
                isActive,
                isCallMonitor,
              },
            ],
          };
        }
        case voiceCallParticipantTypeEnum.external: {
          const displayName =
            voiceProviderContact?.contact?.fullName ||
            voiceProviderContact?.contact?.phoneNumber;

          return {
            ...acc,
            contacts: [
              ...acc.contacts,
              {
                id: `contact-${voiceProviderContact?.id}`,
                displayName,
                hasAudio: !isMuted,
                isActive,
              },
            ],
          };
        }
        default: {
          return acc;
        }
      }
    },
    { agents: [], contacts: [] },
  );

  return parsedParticipants;
};

/*
  Responsible for getting us the vonage conversation_id for the vonage conversation
  that has the call used to contact the agent (for incoming call)
*/
export const getClientAgentIpCallId = (voiceCallObj) => {
  if (isEmpty(voiceCallObj)) return;

  const { voiceConversation } = voiceCallObj;
  const { lastAgentIpCallToMe } = voiceConversation;
  const clientIpCallId =
    lastAgentIpCallToMe?.voiceProviderUserIpCall.clientIpCallId || "";
  const vonageClientConversationId = clientIpCallId.split(":")[0];

  return vonageClientConversationId;
};

export const getVoiceCallActionDescription = ({
  eventType,
  actionObject,
  target,
  shouldMaskPhoneNumber,
}) => {
  switch (eventType) {
    case voiceCallEventTypeEnum.VOICE_CALL_STARTED: {
      const voiceProviderAccountName =
        target.__typename === dataObjectTypenameEnum.voiceProviderAccountObject
          ? target.name
          : actionObject.initiatorVoiceLeg.voiceProviderAccount.name;
      return `started a voice call - ${voiceProviderAccountName}`;
    }

    case voiceCallEventTypeEnum.VOICE_CALL_INVITED: {
      const isActionObjectContact =
        actionObject.__typename ===
        dataObjectTypenameEnum.voiceProviderContactObject;

      const parsedActionObject = isActionObjectContact
        ? getFormattedPhoneNumber({
            displayName: actionObject?.contact?.displayName,
            shouldMaskPhoneNumber,
          })
        : getConversationEventUnionObjectLabel(actionObject);

      return `invited ${parsedActionObject} to the voice call`;
    }

    case voiceCallEventTypeEnum.VOICE_CALL_FAILED: {
      const { callFailureDetail } = actionObject;

      const fallbackDescription = `Call failed with error code ${callFailureDetail}`;

      const voiceCallFailureDescription =
        voiceCallFailureDescriptionLabel[callFailureDetail] ||
        fallbackDescription;

      return voiceCallFailureDescription;
    }

    default:
      return voiceCallEventTypeActionDescriptionLabel[eventType] || "";
  }
};

export const getClickToCallExtensionVoiceCallObj = ({
  voiceConversation,
  userId,
  isStartingVoiceCall = false,
  isMuteActionLoading = false,
  isEndActionLoading = false,
}) => {
  const {
    answeredAt,
    conversation,
    currentParticipants,
    voiceCallStatus,
    initiatorVoiceLeg,
    isOnHold,
    lastOnHoldAt,
  } = voiceConversation;

  const voiceProviderAccountName = initiatorVoiceLeg.voiceProviderAccount.name;

  const myParticipant = getParticipantFromVoiceCall({
    voiceCallParticipants: currentParticipants,
    participantType: voiceCallParticipantTypeEnum.agent,
    participantId: userId,
  });

  const clickToCallExtensionVoiceCallObj = {
    answeredAt,
    lastOnHoldAt,
    conversationId: conversation.id,
    voiceCallStatus,
    voiceProviderAccountName,
    hasMyParticipantAnswered: myParticipant?.isCallAnswered,
    isMicOn: !myParticipant?.isMuted,
    isOnHold,
    isStartingVoiceCall,
    isMuteActionLoading,
    isEndActionLoading,
  };

  return { clickToCallExtensionVoiceCallObj };
};

const getValidPhoneNumberCountryCode = (validPhoneNumber) => {
  const parsedPhoneNumber = parsePhoneNumber(validPhoneNumber);
  return parsedPhoneNumber.country;
};

const getMatchingCountryVPAs = ({
  selectedPhoneNumber,
  voiceProviderAccounts,
}) => {
  const clickedPhoneNumberCountryCode =
    getValidPhoneNumberCountryCode(selectedPhoneNumber);

  const matchingCountryVPAs = voiceProviderAccounts.filter((vpa) => {
    const vpaCountryCode = getValidPhoneNumberCountryCode(vpa.accountId);
    return vpaCountryCode === clickedPhoneNumberCountryCode;
  });

  return matchingCountryVPAs;
};

const getDefaultOutboundCallVPA = ({
  defaultOutboundCallVoiceProviderAccount,
  voiceProviderAccounts,
}) => {
  const hasDefaultOutboundCallVPA =
    defaultOutboundCallVoiceProviderAccount !== null;

  /* In the edge case of default outbound VPA being null in backend, use the first VPA from the list of VPAs */
  return hasDefaultOutboundCallVPA
    ? defaultOutboundCallVoiceProviderAccount
    : voiceProviderAccounts[0];
};

export const getOutboundCallVPASettings = ({
  selectedGroupId,
  groups,
  voiceConfiguration,
  voiceProviderAccounts,
}) => {
  const groupWithOverridenVoiceCallSettings = groups.find((group) => {
    const isGroupVoiceSettingsOverridden = group.ringGroup !== null;
    const isSelectedGroup = group.id === selectedGroupId;
    return isGroupVoiceSettingsOverridden && isSelectedGroup;
  });

  /* Use the group's voice settings if it is overriden */
  if (groupWithOverridenVoiceCallSettings) {
    const { autoSelectOutboundDid, defaultOutboundCallVoiceProviderAccount } =
      groupWithOverridenVoiceCallSettings.ringGroup;

    return {
      isAutoSelectOutboundCallVPA: autoSelectOutboundDid,
      defaultOutboundCallVoiceProviderAccount: getDefaultOutboundCallVPA({
        defaultOutboundCallVoiceProviderAccount,
        voiceProviderAccounts,
      }),
    };
  }

  /* If no overriden group settings, use global voice settings */
  const { autoSelectOutboundDid, defaultOutboundCallVoiceProviderAccount } =
    voiceConfiguration;

  return {
    isAutoSelectOutboundCallVPA: autoSelectOutboundDid,
    defaultOutboundCallVoiceProviderAccount: getDefaultOutboundCallVPA({
      defaultOutboundCallVoiceProviderAccount,
      voiceProviderAccounts,
    }),
  };
};

export const getAutoSelectVPAArrayInClickToCall = ({
  selectedPhoneNumber,
  selectedGroupId,
  groups,
  voiceConfiguration,
  voiceProviderAccounts,
}) => {
  const {
    isAutoSelectOutboundCallVPA,
    defaultOutboundCallVoiceProviderAccount,
  } = getOutboundCallVPASettings({
    selectedGroupId,
    groups,
    voiceConfiguration,
    voiceProviderAccounts,
  });

  if (!isAutoSelectOutboundCallVPA) {
    return [defaultOutboundCallVoiceProviderAccount];
  }

  const matchingCountryVPAs = getMatchingCountryVPAs({
    selectedPhoneNumber,
    voiceProviderAccounts,
  });

  /* If no matching country, use the default outbound VPA */
  if (matchingCountryVPAs.length === 0) {
    return [defaultOutboundCallVoiceProviderAccount];
  } else if (matchingCountryVPAs.length === 1) {
    /* If there is only 1 matching country VPA, return that single VPA */
    return matchingCountryVPAs;
  } else {
    /*
      If there are multiple same-country VPAs, return `ALL VPAs (including VPA from other countries).
      Return the list of VPA with the same country sorted on top eg. US number is selected [+123**, +124**, +60***]
    */
    const clickedPhoneNumberCountryCode =
      getValidPhoneNumberCountryCode(selectedPhoneNumber);

    const sortedVPAs = flow(
      (voiceProviderAccounts) => {
        return sortBy(voiceProviderAccounts, (vpa) => {
          const countryCode = getValidPhoneNumberCountryCode(vpa.accountId);
          return countryCode === clickedPhoneNumberCountryCode ? 0 : 1;
        });
      },

      (sortedVoiceProviderAccounts) => {
        return map(sortedVoiceProviderAccounts, (vpa) => {
          const isDefaultOutboundVPA =
            vpa.accountId === defaultOutboundCallVoiceProviderAccount.accountId;
          return { ...vpa, isDefaultOutboundVPA };
        });
      },
    )(voiceProviderAccounts);

    return sortedVPAs;
  }
};

export const getAutoSelectVPAObjectInWebclient = ({
  selectedPhoneNumber,
  selectedGroupId,
  groups,
  voiceConfiguration,
  voiceProviderAccounts,
}) => {
  const {
    isAutoSelectOutboundCallVPA,
    defaultOutboundCallVoiceProviderAccount,
  } = getOutboundCallVPASettings({
    selectedGroupId,
    groups,
    voiceConfiguration,
    voiceProviderAccounts,
  });

  if (!isAutoSelectOutboundCallVPA) {
    return defaultOutboundCallVoiceProviderAccount;
  }

  const matchingCountryVPAs = getMatchingCountryVPAs({
    selectedPhoneNumber,
    voiceProviderAccounts,
  });

  /* If no matching country, use the default outbound VPA */
  if (matchingCountryVPAs.length === 0) {
    return defaultOutboundCallVoiceProviderAccount;
  }

  const hasMatchingDefaultOutboundVPA = matchingCountryVPAs.some((vpa) => {
    return vpa.accountId === defaultOutboundCallVoiceProviderAccount.accountId;
  });

  /* If default outbound VPA is within matching country, use it */
  if (hasMatchingDefaultOutboundVPA) {
    return defaultOutboundCallVoiceProviderAccount;
  }

  /* If default outbound VPA is not within the matches, use the first matched VPA */
  return matchingCountryVPAs[0];
};
