export const componentVariantEnum = Object.freeze({
  filled: "filled",
  outlined: "outlined",
  transparent: "transparent",
});

export const tableVariantEnum = Object.freeze({
  dense: "dense",
  normal: "normal",
});

export const asyncSelectorVariantEnum = Object.freeze({
  independent: "independent",
  contained: "contained",
});

export const severityEnum = Object.freeze({
  success: "success",
  error: "error",
});
