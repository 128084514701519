import allowedInboxViewEnum, {
  allowedInboxViewOrderByEnum,
  allowedInboxViewSectionEnum,
} from "@/src/enums/allowedInboxViewEnum";
import { conversationStatusEnum } from "@/src/enums/conversation";

const inboxPageData = {
  name: "Inbox",
  urlObject: {
    pathname: "/inbox/[inboxViewSection]/[inboxViewId]/[status]/[orderBy]",
    query: {
      inboxViewSection: allowedInboxViewSectionEnum.special,
      inboxViewId: allowedInboxViewEnum.me,
      status: conversationStatusEnum.active,
      orderBy: allowedInboxViewOrderByEnum.newest,
    },
  },
  checkPermissions: () => {
    return { hasPermission: true };
  },
};

export default inboxPageData;
