import parse from "html-react-parser";
import { parsePhoneNumber } from "libphonenumber-js/max";
import { findLastIndex, isNil, isNumber, mapValues } from "lodash";

import * as conversationListConversationDefinitions from "@/definitions/conversation/conversationListConversationDefinitions";
import {
  allowedInboxViewOrderByEnum,
  conversationCardSizeSelectorValueEnum,
} from "@/enums/allowedInboxViewEnum";
import {
  conversationActionEnum,
  conversationEventTypeEnum,
  conversationStatusEnum,
  conversationStatusInEnum,
} from "@/enums/conversation";
import { fileTypeEnum } from "@/enums/fileEnum";
import * as messageEnums from "@/enums/messageEnum";
import { parsePhoneNumberErrorMessage } from "@/enums/parsePhoneNumberErrorEnum";
import { dataObjectTypenameEnum } from "@/enums/typename";
import { voiceCallEventTypeEnum } from "@/enums/voiceCall";
import * as commonUtils from "@/utils/commonUtils";
import * as graphqlUtils from "@/utils/graphqlUtils";
import * as hsmUtils from "@/utils/hsmUtils";
import { getInboxViewInput } from "@/utils/inboxPageRoutingUtils";
import { sanitizeString } from "@/utils/textUtils";
import { getFormattedPhoneNumber } from "./contactUtils";

export const conversationCardSizeSelectorOptions = [
  { value: conversationCardSizeSelectorValueEnum.compact, label: "Compact" },
  { value: conversationCardSizeSelectorValueEnum.detail, label: "Detail" },
];

/* This map is used by the conversation cards to determine which types of events should show the message content  */
export const shouldUseEventMessageMapping = {
  [conversationEventTypeEnum.conversationNote]: true,
  [conversationEventTypeEnum.message]: true,
  [voiceCallEventTypeEnum.VOICE_CALL_INTERACTION]: true,
  [voiceCallEventTypeEnum.VOICE_CALL_RECORDED]: true,
};

export const getConversationEventUnionObjectLabel = (object) => {
  if (!object) return;

  switch (object.__typename) {
    case dataObjectTypenameEnum.agentObject: {
      return commonUtils.getAgentFullName(object);
    }

    case dataObjectTypenameEnum.contactObject: {
      return getFormattedPhoneNumber({
        displayName: object.displayName,
      });
    }

    case dataObjectTypenameEnum.messagingProviderContactObject: {
      return getFormattedPhoneNumber({
        displayName: object.contact?.displayName,
      });
    }

    case dataObjectTypenameEnum.voiceProviderContactObject: {
      return getFormattedPhoneNumber({
        displayName: object.contact?.displayName,
      });
    }

    default:
      return object.name || "";
  }
};

export const prepareConversationSummary = (conversationSummary) => {
  if (!conversationSummary) return;

  const { summary } = conversationSummary;
  const sanitizedSummary = sanitizeString(summary);
  return parse(sanitizedSummary);
};

export const getMediaMessageContentTypeFromFileType = (fileType) => {
  switch (fileType) {
    case fileTypeEnum.image:
      return messageEnums.mediaMessageTypeEnum.image;
    case fileTypeEnum.video:
      return messageEnums.mediaMessageTypeEnum.video;
    default:
      return messageEnums.mediaMessageTypeEnum.file;
  }
};

export const isSameEvent = (a, b) => {
  if (a.clientReference && b.clientReference) {
    return a.clientReference === b.clientReference;
  }
  return a.id === b.id;
};

/* This is used to customize the event renderer in conversation card & chat panel only */
export const getConversationEventRendererProps = (eventType) => {
  switch (eventType) {
    case voiceCallEventTypeEnum.VOICE_CALL_TIMEOUT:
    case voiceCallEventTypeEnum.VOICE_CALL_TRANSFER_TIMEOUT:
    case voiceCallEventTypeEnum.VOICE_CALL_INVITATION_TIMEOUT:
    case voiceCallEventTypeEnum.VOICE_CALL_FAILED: {
      return { isActorIncluded: false };
    }

    default: {
      return {};
    }
  }
};

export const getEventTimelineEventRendererProps = (eventType) => {
  const isVoiceCallEvent = voiceCallEventTypeEnum[eventType];

  if (isVoiceCallEvent) {
    const isActorIncluded = ![
      voiceCallEventTypeEnum.VOICE_CALL_RECORDED,
      voiceCallEventTypeEnum.VOICE_CALL_TIMEOUT,
      voiceCallEventTypeEnum.VOICE_CALL_TRANSFER_TIMEOUT,
      voiceCallEventTypeEnum.VOICE_CALL_INVITATION_TIMEOUT,
    ].includes(eventType);

    return { isActorIncluded, isNameBold: true };
  }

  switch (eventType) {
    case conversationEventTypeEnum.conversationUnattendedSet:
    case conversationEventTypeEnum.conversationUnattendedUnset: {
      return { isActorIncluded: true };
    }
  }
};

export const getConversationFromConversationEvent = (conversationEvent) => {
  const { actionObject, target, conversation } = conversationEvent;

  const isTargetAConversationObject =
    target?.__typename === dataObjectTypenameEnum.conversationObject;

  const isActionObjectAConversationObject =
    actionObject.__typename === dataObjectTypenameEnum.conversationObject;

  if (isTargetAConversationObject) return target;
  if (isActionObjectAConversationObject) return actionObject;
  return conversation;
};

export const getVoiceConversationFromVoiceCallEvent = (voiceCallEvent) => {
  const { actionObject, target } = voiceCallEvent;

  const isTargetAVoiceConversationObject =
    target?.__typename === dataObjectTypenameEnum.voiceConversationObject;

  const isActionObjectAVoiceConversationObject =
    actionObject.__typename === dataObjectTypenameEnum.voiceConversationObject;

  if (isTargetAVoiceConversationObject) return target;
  if (isActionObjectAVoiceConversationObject) return actionObject;
};

export const separateMessagesFromStandardEvents = (events) => {
  const standardEvents = events.filter((event) => {
    const { eventType } = event;
    const isNonStandardEvent =
      conversationListConversationDefinitions.timelineEventsExclusionArray.includes(
        eventType,
      );

    if (isNonStandardEvent) return false;
    return true;
  });

  const mediaEvents = events.filter((event) => {
    const { eventType, actionObject } = event;
    const isConversationMessage =
      eventType === conversationEventTypeEnum.message;
    const isConversationNote =
      eventType === conversationEventTypeEnum.conversationNote;

    if (!isConversationMessage && !isConversationNote) return false;

    const actionObjectContainsMedia =
      actionObject.mediaConversationMessage ||
      actionObject.mediaConversationNote ||
      hsmUtils.checkIsHsmWithMediaHeader(
        actionObject?.whatsappHsmConversationMessage?.headerType,
      );

    if (actionObjectContainsMedia) return true;
    return false;
  });

  return { mediaEvents, standardEvents };
};

export const getAllowedConversationActions = (allowedActions = []) => {
  return mapValues(conversationActionEnum, (conversationAction) =>
    allowedActions.includes(conversationAction),
  );
};

export const getContactConversationsKeyInRoot = ({ contactId }) =>
  graphqlUtils.getRootQueryKey({
    queryName: "conversations",
    keyArgArray: [{ keyArg: "contactId", value: contactId }],
  });

export const getAllowedInboxViewKeyInRoot = ({
  isPriority,
  allowedInboxViewInput,
  statusIn = [],
}) => {
  const allowedInboxViewKeyArg = !isNil(allowedInboxViewInput)
    ? {
        keyArg: "allowedInboxViewInput",
        value: allowedInboxViewInput,
      }
    : {
        keyArg: "isPriority",
        value: isPriority,
      };

  return graphqlUtils.getRootQueryKey({
    queryName: "conversations",
    keyArgArray: [
      {
        keyArg: "statusIn",
        value: statusIn,
      },
      allowedInboxViewKeyArg,
    ],
  });
};

export const getPastConversationsKeyInRoot = ({ statusIn, contactId }) =>
  graphqlUtils.getRootQueryKey({
    queryName: "conversations",
    keyArgArray: [
      { keyArg: "statusIn", value: statusIn },
      { keyArg: "contactId", value: contactId },
    ],
  });

export const removeConversationFromQueryCache = ({
  client,
  conversation,
  inboxViewSection,
  inboxViewId,
  contactId,
}) => {
  const key = contactId
    ? getContactConversationsKeyInRoot({ contactId })
    : getAllowedInboxViewKeyInRoot({
        statusIn: conversationStatusInEnum.active,
        ...getInboxViewInput({
          inboxViewSection,
          inboxViewId,
        }),
      });

  client.cache.modify({
    id: "ROOT_QUERY",
    fields: {
      [key]: (existing = {}, { readField }) => {
        const { newResults, newTotalCount } =
          graphqlUtils.filterQueryResultsWithTotalCount({
            existing,
            equalityFn: (ref) => readField("id", ref) === conversation.id,
          });

        return {
          results: newResults,
          totalCount: newTotalCount,
          __typename:
            dataObjectTypenameEnum.conversationObjectPaginatedListObject,
        };
      },
    },
  });
};

export const validateSearchContactPhoneNumber = (searchTerm) => {
  const searchTermHasAlphabet = commonUtils.hasText(searchTerm);

  if (searchTermHasAlphabet) return {};

  try {
    const parsedPhoneNumber = parsePhoneNumber(searchTerm);

    return {
      isValid: parsedPhoneNumber.isValid(),
      country: parsedPhoneNumber.country,
      number: parsedPhoneNumber.number,
    };
  } catch (error) {
    return { errorMessage: parsePhoneNumberErrorMessage[error.message] };
  }
};

export const getPriorityFirstOrderingConversationInsertIndex = ({
  existing,
  conversationRef,
  readField,
}) => {
  /* Check if last conversation is non-priority or if all conversations have been fetched */
  const isAllPriorityConversationsFetched =
    !readField("isPriority", existing.results[existing.results.length - 1]) ||
    existing.results.length === existing.totalCount;

  const indexAfterPrioritySection =
    !readField("isPriority", conversationRef) &&
    findLastIndex(existing.results || [], (ref) =>
      readField("isPriority", ref),
    ) + 1;

  return indexAfterPrioritySection
    ? isAllPriorityConversationsFetched
      ? indexAfterPrioritySection
      : -1
    : null;
};

/*
  New events to a conversation in focus will not move it up the list
  but will move to the order it should be in on switch.
*/
export const reorderFocusedConversationOnSwitch = ({
  client,
  routerQuery = {},
  focusedConversationNextPositionData = {},
}) => {
  const statusIn = (() => {
    switch (routerQuery.status) {
      case conversationStatusEnum.active: {
        return conversationStatusInEnum.active;
      }

      case conversationStatusEnum.resolved: {
        return conversationStatusInEnum.resolved;
      }
    }
  })();

  const key = getAllowedInboxViewKeyInRoot({
    statusIn,
    ...getInboxViewInput({
      inboxViewSection: routerQuery.inboxViewSection,
      inboxViewId: routerQuery.inboxViewId,
    }),
  });

  client.cache.modify({
    id: "ROOT_QUERY",
    fields: {
      [key]: (existing = {}, { readField }) => {
        const { totalCount } = existing;
        const conversationRef = client.cache.identify({
          id: focusedConversationNextPositionData.id,
          __typename: dataObjectTypenameEnum.conversationObject,
        });

        /* Resolve negative index if any */
        const insertAt = (() => {
          switch (routerQuery.orderBy) {
            case allowedInboxViewOrderByEnum.oldest: {
              return (
                focusedConversationNextPositionData.nextPosition + totalCount
              );
            }

            case allowedInboxViewOrderByEnum.priorityFirst: {
              const indexAfterPrioritySection =
                !focusedConversationNextPositionData.isPriority &&
                getPriorityFirstOrderingConversationInsertIndex({
                  existing,
                  conversationRef,
                  readField,
                });

              if (!isNumber(indexAfterPrioritySection))
                return focusedConversationNextPositionData.nextPosition;

              return (
                focusedConversationNextPositionData.nextPosition +
                  indexAfterPrioritySection >=
                  0 && indexAfterPrioritySection
              );
            }
            default: {
              return focusedConversationNextPositionData.nextPosition;
            }
          }
        })();

        const newResults = graphqlUtils.reorderQueryItem({
          existing,
          equalityFn: (ref) =>
            readField("id", ref) === focusedConversationNextPositionData.id,
          insertAt,
        });

        return {
          results: newResults,
          totalCount: existing.totalCount,
          __typename:
            dataObjectTypenameEnum.conversationObjectPaginatedListObject,
        };
      },
    },
  });
};
