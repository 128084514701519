import { EditorState, convertToRaw } from "draft-js";
import { findLastIndex, mapValues } from "lodash";

import EditorHelper from "@/components/RichTextEditor/EditorHelper";
import {
  bodyRawTypeEnum,
  entityTypeEnum,
  mentionFormatEnum,
} from "@/enums/editor";
import { fileTypeEnum, fileUploadStatusEnum } from "@/enums/fileEnum";
import { mediaMessageTypeEnum } from "@/enums/messageEnum";
import { messageTemplatePartButtonActionTypeEnum } from "@/enums/messageTemplateEnum";
import { replaceInArray } from "@/utils/commonUtils";
import { validateNonEmptyString } from "@/utils/formValidationUtils";
import { getMediaMessageContentTypeFromFileType } from "@/utils/inboxPageUtils";

export const getMediaIdentifiers = (editorStates) => {
  return editorStates.map((editorState) => {
    const mediaEntities = EditorHelper.getEntities({
      editorState,
      entityType: entityTypeEnum.media,
    });

    if (mediaEntities.length > 0) return mediaEntities[0].data.src;
    else return null;
  });
};

export const getMediaStatusArray = ({ mediaIdentifiers, mediaUploads }) => {
  return mediaIdentifiers.map((mediaIdentifier) => {
    const mediaUpload = mediaUploads[mediaIdentifier];
    /*
      If mediaIdentifier is falsy, no media was found in the editorState for that index
      If mediaUpload is falsy, the media came from backend and was not just uploaded
    */
    if (!mediaIdentifier || !mediaUpload)
      return { status: fileUploadStatusEnum.success };

    const { status, uuid, error } = mediaUpload;

    if (status === fileUploadStatusEnum.success) return { uuid, status };
    if (status === fileUploadStatusEnum.failed)
      return { errorMessage: error.message, status };
    return { status: fileUploadStatusEnum.inProgress };
  });
};

const constructTextMessageTemplatePart = ({ rawEditorState }) => {
  const modifiedRawData = EditorHelper.prepareModifiedRawDraftJsDataForBackend({
    rawEditorState,
  });

  return {
    bodyRawType: bodyRawTypeEnum.draftJs,
    bodyRawData: JSON.stringify(modifiedRawData),
  };
};

const constructMediaMessageTemplatePart = ({ rawEditorState, mediaUuid }) => {
  const { entityMap } = rawEditorState;
  const mediaEntityData = Object.values(entityMap).find(
    (entity) => entity.type === entityTypeEnum.media,
  ).data;

  const modifiedRawData = EditorHelper.prepareModifiedRawDraftJsDataForBackend({
    rawEditorState,
  });

  const hasText = modifiedRawData.blocks.some((block) =>
    validateNonEmptyString(block.text),
  );

  return {
    mediaMessageType: getMediaMessageContentTypeFromFileType(
      mediaEntityData.type,
    ),
    ...(hasText && {
      captionRawType: bodyRawTypeEnum.draftJs,
      captionRawData: JSON.stringify(modifiedRawData),
    }),

    /* Get existing media UUID or Get UUID returned from media upload  */
    media: mediaEntityData.uuid || mediaUuid,
  };
};

const constructMessageTemplatePartButtons = ({ buttons }) => {
  return buttons.map((button, index) => {
    const returnObj = {
      order: index,
      buttonText: button.buttonText,
      buttonActionType: button.actionType.value,
    };

    switch (button.actionType.value) {
      case messageTemplatePartButtonActionTypeEnum.URL: {
        returnObj.buttonUrl = button.urlAction;
        break;
      }
      case messageTemplatePartButtonActionTypeEnum.VISIT_CONTENT: {
        returnObj.landingPageDocumentId = button.visitContentAction.value;
        break;
      }

      default: {
        break;
      }
    }

    return returnObj;
  });
};

export const constructMessageTemplatePartsFromEditorStates = ({
  editorStates = [],
  mediaUuidArray = [],
  buttonsArray = [],
}) => {
  const messageTemplateParts = editorStates.map((editorState, index) => {
    const isValidMessage = EditorHelper.isMessageValid({ editorState });
    if (!isValidMessage) return null;

    const hasFile = EditorHelper.hasFile({ editorState });
    const rawEditorState = convertToRaw(editorState.getCurrentContent());

    const buttons = constructMessageTemplatePartButtons({
      buttons: buttonsArray[index] || [],
    });

    if (!hasFile) {
      return {
        textMessageTemplatePart: constructTextMessageTemplatePart({
          rawEditorState,
        }),
        buttons,
      };
    }

    return {
      mediaMessageTemplatePart: constructMediaMessageTemplatePart({
        rawEditorState,
        mediaUuid: mediaUuidArray[index],
      }),
      buttons,
    };
  });

  return messageTemplateParts
    .filter((item) => !!item)
    .map((item, index) => ({ ...item, partOrdering: index }));
};

export const createEditorStateFromMediaMessageTemplatePart = ({
  mediaMessageTemplatePart,
}) => {
  const { media, caption, mediaMessageType } = mediaMessageTemplatePart;

  const mediaEditorState = (() => {
    if (caption) {
      return EditorHelper.createEditorStateFromHtml({
        htmlBody: caption,
      });
    }
    return EditorState.createEmpty();
  })();

  const type = (() => {
    if (mediaMessageType === mediaMessageTypeEnum.image)
      return fileTypeEnum.image;
    if (mediaMessageType === mediaMessageTypeEnum.video)
      return fileTypeEnum.video;
    return fileTypeEnum.application;
  })();

  return EditorHelper.addMediaEntity({
    mediaEntityData: {
      ...EditorHelper.getMediaBlockItemInfo({
        file: {
          type,
          name: media.filename,
          src: media.mediaFileUrl,
          size: media.mediaFileSize,
        },
        isFromTemplate: true,
      }),
      uuid: media.uuid,
    },
    focusedEditor: mediaEditorState,
  });
};

export const transformEntities = (rawEditorState) => {
  const { blocks, entityMap } = rawEditorState;

  /* Find all the mention text that exists within the blocks of the message */
  const mentionTextMapping = {};
  blocks.forEach((block) => {
    const { text, entityRanges } = block;

    entityRanges.forEach((entityRange) => {
      const { key, offset, length } = entityRange;
      const entity = entityMap[key];
      if (entity.type !== entityTypeEnum.mention) return;

      const entityText = text.slice(offset, offset + length);
      mentionTextMapping[key] = entityText;
    });
  });

  /* Transform the entities in the format expected by backend */
  const newEntityMap = mapValues(entityMap, (entity, key) => {
    switch (entity.type) {
      case entityTypeEnum.mention: {
        const { id, name, user } = entity.data.mention;
        const mentionText = mentionTextMapping[key];
        const format = (() => {
          switch (mentionText) {
            case user.firstName:
              return mentionFormatEnum.firstName;
            case user.lastName:
              return mentionFormatEnum.lastName;
            default:
              return mentionFormatEnum.fullName;
          }
        })();

        return {
          ...entity,
          data: { mention: { agent_id: id, name, format } },
        };
      }

      case entityTypeEnum.emv: {
        return { ...entity, data: { emv: { name: entity.data.name } } };
      }

      default: {
        return entity;
      }
    }
  });

  return { blocks, entityMap: newEntityMap };
};

export const trimRawEditorState = (rawEditorState) => {
  const { blocks, entityMap } = rawEditorState;

  const firstTextBlockIndex = blocks.findIndex(
    (block) => block.text.search(/\S/) > -1,
  );
  const startIndex = firstTextBlockIndex > -1 ? firstTextBlockIndex : 0;

  const lastTextBlockIndex = findLastIndex(
    blocks,
    (block) => block.text.search(/\S/) > -1,
  );

  const newBlocks =
    lastTextBlockIndex > -1
      ? replaceInArray({
          array: blocks,
          index: lastTextBlockIndex,
          element: {
            ...blocks[lastTextBlockIndex],
            text: blocks[lastTextBlockIndex].text.trim(),
          },
        }).slice(startIndex, lastTextBlockIndex + 1)
      : blocks.slice(startIndex);

  return { blocks: newBlocks, entityMap };
};
